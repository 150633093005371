import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Box, Button, Typography } from '@mui/material';
import { createApiClient } from '../services/apiClient';

const UserInvitation: React.FC = () => {
  const location = useLocation();
  const hostAuth = import.meta.env.VITE_HOST_AUTH;
  const params = new URLSearchParams(location.search);
  const invitationCode = params.get('code');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [inviteMessage, setInviteMessage] = useState<string | null>(null);
  const [checkInviteResult, setCheckInviteResult] = useState<CheckInviteResponse | null>(null);

  useEffect(() => {
    
    if (!invitationCode) {
      setErrorMessage('Invalid invitation code.');
      return;
    }

    // // Check if the user is already authenticated
    // const checkAuthUser = async () => {
    //   try {
    //     const response = await axios.get(`/auth/check-auth`, { withCredentials: true });
    //     if (response.status === 200 && response.data.isAuthenticated) {
    //       setIsAuthenticated(true);
    //     }
    //   } catch (error) {
    //     console.error('Error checking authentication:', error);
    //   }
    // };
    // checkAuthUser();

    if(checkInviteResult == null)
    {
      checkRegistration();
    }
 
  }, [checkInviteResult]);

  const logout = () => {
    const redirectUrl = encodeURIComponent(window.location.href);
    const endpointAuth = `${hostAuth}/auth/logout?redirectUrl=${redirectUrl}`;
    window.location.href = endpointAuth;
  };

  const checkAuth = async () => {
    try {
      console.info('checkAuthUserClaims()');
      // const response = await axios.get(`/auth/check-auth`, { withCredentials: true });
      const apiClient = createApiClient(window.location.origin);
      const response = await apiClient.get<CheckAuthResponse>('/auth/check-auth');
      if (response.status === 200 && response.data.isAuthenticated) {
        console.debug('User is authenticated with response.data.user.email:' + response.data?.user?.email);  
        return response.data.user;
      }
      console.debug('Authentication check failed. User not authenticated:', response.data.isAuthenticated);
      return null;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error during authentication check:', error.message);
      } else {
        console.error('Unexpected error during authentication check:', error);
      }
      return null;
    }
  };

  const checkRegistration = async () => {
    try {
        setCheckInviteResult(null);
        setErrorMessage(null);
        setInviteMessage(null);

        //const response = await axios.get(`/api/tenants/check-invite/${invitationCode}`, { withCredentials: true });
        const apiClient = createApiClient(window.location.origin);
        const response = await apiClient.get<CheckInviteResponse>(`/api/tenants/check-invite/${invitationCode}`);

        console.debug(`Check invite api response isInviteValid : ${response.data.isInviteValid}, 
          isUserAuthenticated: ${response.data.isUserAuthenticated}, isUserAuthenticatedValid: ${response.data.isUserAuthenticatedValid}, response.status: ${response.status}`);
        
        const checkInviteResult = response.data; 
        setCheckInviteResult(checkInviteResult);
        if(response.status !== 200)
        {
          console.debug(`Invite code is invalid. ${response.status} - checkInviteResult.isInviteValid: ${checkInviteResult.isInviteValid}`);
          throw new Error('Si è verificato un errore imprevisto. Si prega di riprovare.');
        }

        if (checkInviteResult.isInviteValid) {
          if(checkInviteResult.isUserAuthenticated) {
            if(!checkInviteResult.isUserAuthenticatedValid) {
              const checkUser = await checkAuth();
              console.debug(`L'utente risulta autenticato con un checkUser?.email: ${checkUser?.email} non valida per questo invito.`);
              setInviteMessage(`L'utente risulta autenticato con un email: ${checkUser?.email} non valida per questo invito. Si prega di fare prima il logout e riprovare.`);
              return;
            }
            // await completeRegistration();
          }
        }
        else
        {
          console.debug(`Invite code is invalid. ${response.status} - checkInviteResult.isInviteValid: ${checkInviteResult.isInviteValid}`);
          setErrorMessage('Codice invito non valido o scaduto.');
        }
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
          console.error('Failed to check invite - axiosError.response?.status:', axiosError.response?.status);
        }
        else if (axios.isAxiosError(error)) {
          console.error('Check invite code failed:', error.message);
        } else {
          console.error('Unexpected error during check invite:', error);
        }
        setCheckInviteResult(null);
        setErrorMessage('Si è verificato un errore imprevisto. Si prega di riprovare.');
        return;
    }   
  };

  const completeRegistration = async () => {
    
    setIsLoading(true);
    setErrorMessage(null);
    setInviteMessage(null);

    console.info(`Complete registration - Invitation code: ${invitationCode} - Is tenant subdomain: ${isTenantSubdomain()}`);
    
    if (!invitationCode || !isTenantSubdomain()) {
      setErrorMessage('Link di invito non valido.');
      return;
    }

    if (checkInviteResult?.isInviteValid) {

      if(!checkInviteResult.isUserAuthenticated)
      {
        console.error('completeRegistration() - checkInviteResult.isUserAuthenticated is: ' + checkInviteResult.isUserAuthenticated);
        setErrorMessage('L\'utente non risulta essere loggato o la sessione è scaduta. Si prega di riprovare cliccando sul link di invito.');
        return;
      }

      const user = await checkAuth();
      if (user) {
        const tenantUrl = `https://${window.location.hostname}:4001`;
        console.debug(`checkAuthUserClaims() is OK - Call /api/users/joining-user - tenantUrl: ${window.location.hostname}`);
        try {

          const apiClient = createApiClient(window.location.origin);
          const response = await apiClient.post<UserJoinRequest>('/api/users/joining-user', {
            inviteCode: invitationCode
          });

          if (response.status === 200) {
            console.debug('/api/users/joining-user Response is OK and Redirect at Home');
            window.location.href = `${tenantUrl}/home`;
          } else {
            console.error('/api/users/joining-user response.status NOT 200: ' + response.status);
            setErrorMessage('Impossibile elaborare l\'invito. Si prega di riprovare.');
          }

        } catch (error) {
          if (axios.isAxiosError(error)) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage('Si è verificato un errore imprevisto. Si prega di riprovare.');
          }
          setCheckInviteResult(null);
        } 

      } else {
        // checkInviteResult.isUserAuthenticatedValid = false;
        // setCheckInviteResult(checkInviteResult);
        setInviteMessage('L\'utente non risulta essere loggato o la sessione è scaduta. Si prega di riprovare cliccando sul link di invito.');
        console.error('completeRegistration() -> checkAuthUserClaims() -> User is Null');
      }
    } else {
      console.debug('Error: invite code is invalid.');
      setErrorMessage('Invalid invitation code.');
    }

    setIsLoading(false);
  };

  const isTenantSubdomain = () => {
    const hostname = window.location.hostname;
    return hostname !== 'app.imdevlablocal.com';
  };

  const loginOrRegistration = () => {
    if(!checkInviteResult?.isUserAuthenticated)
    {
      const returnUrl = `${window.location.origin}/user-invitation?code=${invitationCode}`;
      const encodedReturnUrl = encodeURIComponent(returnUrl);
      const endpointAuth = `${hostAuth}/auth/login?returnUrl=${encodedReturnUrl}`;
      console.info(`CALL ${endpointAuth}`);
      window.location.href = endpointAuth;
    }
    else
    {
      console.info(`loginOrRegistration() - User is already authenticated.`);
      completeRegistration();
    }
  }
 
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ textAlign: 'center', padding: '2rem' }}>
      <Typography variant="h6">Benvenuto nella pagina di invito</Typography>
      <br />
      <br />
        <>
          {(console.debug(`Prerender page -
            inviteMessage: ${inviteMessage} - 
            checkInviteResult: ${checkInviteResult?.isInviteValid} - 
            checkInviteResult: ${checkInviteResult?.isUserAuthenticated} - 
            checkInviteResult: ${checkInviteResult?.isUserAuthenticatedValid}`)
          )}
          {inviteMessage && (
            <>
              <Typography variant="body1" color="error">{inviteMessage}</Typography>
            </>
          )}
          {checkInviteResult?.isInviteValid ? (
          <>
            {checkInviteResult?.isUserAuthenticated ? (
              <>
                {checkInviteResult?.isUserAuthenticatedValid ? (
                  <>
                    <Typography variant="body1">Clicca sul pulsante per completare la precedura di registrazione.</Typography>
                    <br /> 
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={completeRegistration}
                      sx={{ marginTop: '2rem' }}
                      aria-label="Register using invitation link"
                    >
                      Conferma per accedere
                    </Button>
                  </>
                ) : (
                  <>
                    {/* <Typography variant="body1">L'utente non è valido per questo invito. Si prega di effettuare prima il logout e riprovare a fare clic sul link nell'email.</Typography> */}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={logout}
                      sx={{ marginTop: '2rem' }}
                      aria-label="Logout"
                    >
                      Logout
                    </Button>
                  </>
                )}
              </>
            ) : (
              <>
                <Typography variant="body1">Clicca sul pulsante per registrare il tuo account utente oppure effettua il login se il tuo account è già registrato.</Typography>
                <br /> 
                <Button
                  variant="contained"
                  color="primary"
                  onClick={loginOrRegistration}
                  sx={{ marginTop: '2rem' }}
                  aria-label="Register using invitation link"
                >
                  Login
                </Button>
              </>
            )}
          </>
          ) : (
            <>
              {/* <Typography variant="body1">Spiacenti, l'invito non è valido o è scaduto.</Typography> */}
            </>
          )}
        </> 
      <br />
      {errorMessage ?? (
        <>
        <Typography variant="body1" color="error">{errorMessage}</Typography>
        </>
      )}
    </Box>
  );
};

export default UserInvitation;
