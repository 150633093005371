import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import { useAuth } from '../context/AuthProvider';

const Navbar: React.FC = () => {
  const { isAuthenticated, login, logout, isMainDomain } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isUserInvitationPath = location.pathname === '/user-invitation';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 2,
        borderRight: '1px solid #ddd',
        height: '100vh',
        width: '200px',
        position: 'fixed',
        top: 0,
        left: 0,
        bgcolor: 'background.paper',
      }}
    >
      <Button onClick={() => navigate('/home')}>Home</Button>
      {isAuthenticated && !isUserInvitationPath ? (
        <>
          {isMainDomain ? (
            <>
              <Button onClick={() => navigate('/profile')}>Profilo utente</Button>
              <Button onClick={() => navigate('/create-tenant')}>Crea azienda</Button>
            </>
          ) : (
            <>
              <Button onClick={() => navigate('/profile')}>Profilo utente</Button>
              <Button onClick={() => navigate('/create-invite')}>Crea invito utente</Button>
              {/* <Button onClick={() => navigate('/create-product')}>Crea prodotto</Button> */}
            </>
          )}
          <Button onClick={logout}>Logout</Button>
        </>
      ) : (
        !isUserInvitationPath && <Button onClick={login}>Login</Button>
      )}
    </Box>
  );
};

export default Navbar;
