// src/auth2/AuthProvider.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

// const hostServer = 'https://app.imdevlablocal.com:4001'; // Define your server URL here
// const hostServer = 'https://app.imdevlab.com';

const hostServer = import.meta.env.VITE_HOST_AUTH;
console.log('hostServer: ', hostServer);

interface UserClaim {
  Type: string;
  Value: string;
}

interface User {
  userName: string;
  userClaims: UserClaim[];
  host: string;
}

interface AuthState {
  isLoggedIn: boolean;
  user: User | null;
}

const AuthContext = createContext<AuthState>({ isLoggedIn: false, user: null });

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({ isLoggedIn: false, user: null });

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get<User>(`${hostServer}/users/user/me`, {
          withCredentials: true,  
        });

        if (response.status === 200) {
          setAuthState({ isLoggedIn: true, user: response.data });
        } else {
          setAuthState({ isLoggedIn: false, user: null });
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        setAuthState({ isLoggedIn: false, user: null });
      }
    };

    fetchAuthStatus();
  }, []);

  return (
    <AuthContext.Provider value={authState}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
