import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { TextField, Button, Box, Alert, Link } from '@mui/material';
import { useAuth } from '../context/AuthProvider';
import { createApiClient } from '../services/apiClient';

  interface InviteTenantUser {
    email: string;
    tenant: string;
  }

  interface InviteResponse {
    email: string;
    tenant: string;
    code: string;
    link: string;
  }

const CreateInvite: React.FC = () => {
  const { isAuthenticated } = useAuth(); // Usa solo isAuthenticated
  const [emailUser, setEmailUser] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [inviteResponse, setInviteResponse] = useState<InviteResponse | null>(null);
  const apiClient = createApiClient(window.location.origin);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailUser(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newInvite: InviteTenantUser = { 
      email: emailUser,
      tenant: window.location.hostname
    };
    try {
      if (!isAuthenticated) {
        setMessage('Per creare un invito utente è necessario essere autenticati.');
        return;
      }

      const response = await apiClient.post('/api/tenants/create-invite', newInvite, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        const inviteData: InviteResponse = response.data;
        setInviteResponse(inviteData);
        setMessage('');
        setEmailUser('');
      } else {
        setMessage('Impossibile creare un invito utente.');
        setInviteResponse(null);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        setMessage(`Errore: ${axiosError.response.data || axiosError.message}`);
      } else {
        setMessage('Si è verificato un errore sconosciuto.');
      }
      setInviteResponse(null);
    }
  };

  return (
    <div className="container">
      <h2>Crea invito utente</h2>
      <Box sx={{ mt: 4 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email utente"
            variant="outlined"
            fullWidth
            value={emailUser}
            onChange={handleInputChange}
            required
            margin="normal"
          />
          <Button variant="contained" color="primary" type="submit">
            Crea invito.
          </Button>
        </form>
        {message && (
          <Alert severity={message.startsWith('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
        {inviteResponse && (
          <Box sx={{ mt: 2 }}>
            <Alert severity="success">
              <p>Invito utente creato con successo!</p>
              <p><strong>Email:</strong> {inviteResponse.email}</p>
              <p><strong>Azienda:</strong> {inviteResponse.tenant}</p>
              <p><strong>Codice invito:</strong> {inviteResponse.code}</p>
              <p><strong>Link di invito:</strong> <Link href={inviteResponse.link} target="_blank" rel="noopener">{inviteResponse.link}</Link></p>
            </Alert>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default CreateInvite;