let onAuthErrorCallback: (() => void) | null = null;

const setOnAuthErrorCallback = (callback: () => void) => {
  onAuthErrorCallback = callback;
};

const handleAuthError = () => {
  if (onAuthErrorCallback) {
    onAuthErrorCallback();
  }
};

export { setOnAuthErrorCallback, handleAuthError };
