import axios, { AxiosInstance, AxiosError } from 'axios';
import { handleAuthError } from './authHandler';

// Interceptor to handle 401 errors
const addAuthInterceptor = (apiClient: AxiosInstance) => {
  apiClient.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        handleAuthError();
      }
      return Promise.reject(error);
    }
  );
};

const createApiClient = (baseURL: string): AxiosInstance => {
  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      'X-Auto-Auth': 'true',
    },
  });

  addAuthInterceptor(apiClient);
  return apiClient;
};

const createAuthClient = (): AxiosInstance => {
  const hostAuth = import.meta.env.VITE_HOST_AUTH;
  const authClient = axios.create({
    baseURL: hostAuth,
    withCredentials: true,
  });

  addAuthInterceptor(authClient);
  return authClient;
};

export { createApiClient, createAuthClient };
