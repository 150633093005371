import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import { restrictedRoutesMainDomain, restrictedRoutesSubdomain } from '../config/routeConfig';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading, authError, isMainDomain } = useAuth();
  const location = useLocation();

  useEffect(() => {
    // Check additional logic based on path if needed
  }, [location.pathname]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated === false) {
    return <Navigate to="/unauthorized" state={{ from: location, error: authError || 'You are not authenticated' }} />;
  }

  // Block specific paths based on domain
  if (isMainDomain && restrictedRoutesMainDomain.includes(location.pathname)) {
    return <Navigate to="/unauthorized" state={{ from: location, error: 'You are not authorized to access this route on this domain' }} />;
  }

  if (!isMainDomain && restrictedRoutesSubdomain.includes(location.pathname)) {
    return <Navigate to="/unauthorized" state={{ from: location, error: 'You are not authorized to access this route on a subdomain' }} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
