import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const CreateTenant: React.FC = () => {
    // Set Axios to send cookies with every request
    axios.defaults.withCredentials = true;

    const [tenantName, setTenantName] = useState<string>('');
    const [tenantDescription, setTenantDescription] = useState<string>('');
    const hostServer = window.location.origin; //window.location.href;

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTenantName(e.target.value);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTenantDescription(e.target.value);
    };

    const handleCreateTenant = async () => {
        try {
            console.log('tenantName: ', tenantName);
            console.log('tenantDescription: ', tenantDescription);
            console.log('hostServer: ', hostServer);
            
            const response = await axios.post(`${hostServer}/api/tenants/create`, {
                name: tenantName,
                description: tenantDescription,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            
            console.log(response.data);
            alert(`Tenant ${tenantName} created successfully!`);

            const tenantUrl = new URL(import.meta.env.VITE_HOST_TENANT);
            tenantUrl.hostname = `${tenantName}.${tenantUrl.hostname.split('.').slice(1).join('.')}`;

            window.location.href = tenantUrl.toString();

        } catch (error) {
            console.error('Failed to create tenant:', error);
            alert('Failed to create tenant!');
        }
    };

    return (
        <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}
            onSubmit={(e) => { e.preventDefault(); handleCreateTenant(); }}
        >
            <TextField
                label="Tenant Name"
                variant="outlined"
                value={tenantName}
                onChange={handleNameChange}
                required
                sx={{ mb: 2, width: '100%', maxWidth: '400px' }}
            />
            <TextField
                label="Tenant Description"
                variant="outlined"
                value={tenantDescription}
                onChange={handleDescriptionChange}
                sx={{ mb: 2, width: '100%', maxWidth: '400px' }}
            />
            <Button variant="contained" color="primary" type="submit">
                Create Tenant
            </Button>
        </Box>
    );
};

export default CreateTenant;
