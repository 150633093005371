import React, { useState } from 'react';
import { useAuth } from '../context/AuthProvider';
import { createApiClient } from '../services/apiClient';
import { List, ListItem, ListItemText, CircularProgress, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

interface Tenant {
  name: string;
  link: string;
}

const HomePage: React.FC = () => {
  const { isAuthenticated, user, tenantName, login, isLoading, setIsAuthenticated, setAuthError } = useAuth();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [loadingTenants, setLoadingTenants] = useState<boolean>(false);
  const [searchCompleted, setSearchCompleted] = useState<boolean>(false);
  const navigate = useNavigate();

  const apiClient = createApiClient(window.location.origin);

  const handleGetTenants = async () => {
    setLoadingTenants(true);
    setSearchCompleted(false); // Reset search completed status
    try {
      const response = await apiClient.get<{ tenantsList: Tenant[] }>('/api/tenants/gettenants');
      
      const tenantsWithPort = response.data.tenantsList.map(tenant => ({
        ...tenant,
        link: tenant.link.includes(':4001') ? tenant.link : `${tenant.link}:4001`,
      }));
      setTenants(tenantsWithPort);
      console.log('Tenants:', tenantsWithPort);
      
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 401) {
        if (setAuthError) {
          setAuthError('Sessione scaduta. Effettuare nuovamente il login.');
        }
        if (setIsAuthenticated) {
          setIsAuthenticated(false);
        }
        navigate('/unauthorized', { state: { from: window.location.pathname, error: 'La sessione è scaduta. Effettuare nuovamente il login.' } });
      } else {
        console.error('Failed to get tenants:', axiosError);
        alert('Si è verificato un errore. Non è stato possibile trovare l\'azienda!');
      }
    } finally {
      setLoadingTenants(false);
      setSearchCompleted(true); // Mark search as completed
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="container">
      {isAuthenticated ? (
        <div>
          {/* <h3>Tenant: {tenantName}</h3> */}
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Dominio</TableCell>
                  <TableCell>{tenantName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Nome utente</TableCell>
                  <TableCell>{user?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{user?.email}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div>
            <h3>Elenco aziende</h3>
            {tenants.length > 0 ? (
              <List>
                {tenants.map((tenant, index) => (
                  <ListItem key={index}>
                    <ListItemText 
                      primary={tenant.name} 
                      secondary={<a href={`https://${tenant.link}`} target="_blank" rel="noopener noreferrer">{tenant.link}</a>} 
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              searchCompleted && <p>Nessuna azienda trovata.</p>
            )}
          </div>
          <div>
            <Button variant="contained" onClick={handleGetTenants} disabled={loadingTenants}>
              {loadingTenants ? <CircularProgress size={24} /> : 'Cerca'}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <p>Non hai effettuato il login. Effettuare il login per visualizzare le aziende.</p>
          <Button onClick={login}>Login</Button>
        </div>
      )}
    </div>
  );
};

export default HomePage;
