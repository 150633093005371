import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

const Unauthorized: React.FC = () => {
  const { isAuthenticated, login } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const errorMessage = (location.state as { error?: string })?.error || 'You are not authorized to view this page.';

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated, navigate]);

  return (
    <div className="container">
      <h2>Pagina utente anonimo</h2>
      <div>
        <p style={{ color: 'red' }}>{errorMessage}</p>
        <button onClick={login}>Login</button>
      </div>
    </div>
  );
};

export default Unauthorized;
