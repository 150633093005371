import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthProvider';
import HomePage from './components/HomePage';
import UserProfile from './components/UserProfile';
import CreateTenant from './components/CreateTenant';
import CreateEstimate from './components/CreateEstimate';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/NavBar';
import Unauthorized from './components/Unauthorized';
import { Box, Container } from '@mui/material';
import UserInvitation from './components/UserInvitation';
import CreateInvite from './components/CreateInvite';

const AppContent: React.FC = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Navbar />
      <Container sx={{ marginLeft: '200px', padding: 2, flexGrow: 1 }}>
        <Routes>
          <Route path="/profile" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path="/create-tenant" element={<ProtectedRoute><CreateTenant /></ProtectedRoute>} />
          <Route path="/create-product" element={<ProtectedRoute><CreateEstimate /></ProtectedRoute>} />
          <Route path="/create-invite" element={<ProtectedRoute><CreateInvite /></ProtectedRoute>} />
          <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/user-invitation" element={<UserInvitation />} />
        </Routes>
      </Container>
    </Box>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
