// routeConfig.ts

export const restrictedRoutesMainDomain = [
  '/create-product',
  '/user-invitation',
  '/create-invite',
];

export const restrictedRoutesSubdomain = [
  '/create-tenant',
];

export const unprotectedPaths = [
  '/user-invitation',
  '/unauthorized',
];
