import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { TextField, Button, Box, Alert } from '@mui/material';
import { useAuth } from '../context/AuthProvider';
import { createApiClient } from '../services/apiClient';

interface Estimate {
  id?: number;
  name: string;
}

const CreateEstimate: React.FC = () => {
  const { isAuthenticated } = useAuth(); // Usa solo isAuthenticated
  const [estimateName, setEstimateName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const apiClient = createApiClient(window.location.origin);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEstimateName(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newEstimate: Estimate = { name: estimateName };

    try {
      if (!isAuthenticated) {
        setMessage('Per creare un preventivo è necessario essere autenticati.');
        return;
      }

      const response = await apiClient.post('/estimates/create', newEstimate, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200 || response.status === 201) {
        const createdEstimate: Estimate = response.data;
        setMessage(`Preventivo creato con ID: ${createdEstimate.id}`);
        setEstimateName('');
      } else {
        setMessage('Impossibile creare un preventivo.');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        setMessage(`Errore: ${axiosError.response.data || axiosError.message}`);
      } else {
        setMessage('Si è verificato un errore sconosciuto.');
      }
    }
  };

  return (
    <div className="container">
      <h2>Crea un nuovo preventivo</h2>
      <Box sx={{ mt: 4 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nome preventivo"
            variant="outlined"
            fullWidth
            value={estimateName}
            onChange={handleInputChange}
            required
            margin="normal"
          />
          <Button variant="contained" color="primary" type="submit">
            Conferma
          </Button>
        </form>
        {message && (
          <Alert severity={message.startsWith('Error') ? 'error' : 'success'} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
      </Box>
    </div>
  );
};

export default CreateEstimate;
