import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { AxiosError } from 'axios';
import { createApiClient } from '../services/apiClient';
import { setOnAuthErrorCallback } from '../services/authHandler';
import { unprotectedPaths } from '../config/routeConfig';

const hostAuth = import.meta.env.VITE_HOST_AUTH;

interface AuthState {
  isAuthenticated: boolean | null;
  isLoading: boolean;
  user: User | null;
  tenantName: string | null;
  login: () => void;
  logout: () => void;
  authError: string | null;
  setAuthError?: (error: string | null) => void;
  setIsAuthenticated?: (auth: boolean | null) => void;
  isMainDomain: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthState | undefined>(undefined);

const isMainDomain = () => {
  const hostname = window.location.hostname;
  return hostname === 'app.imdevlab.com';
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [tenantName, setTenantName] = useState<string | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [isMainDomainState] = useState(isMainDomain());

  // const location = useLocation();

  useEffect(() => {
    // Check authentication on page load
    //TODO: to be implemented when the /unauthorized path is implemented
    const isUnprotectedPath = unprotectedPaths.includes(window.location.pathname);
    if(isUnprotectedPath) {   
      console.log('AuthProvider - Unprotected Path : ' + window.location.pathname);
      setIsLoading(false);
      setAuthError(null);
      return; 
    }

    const checkAuth = async () => {
      const apiClient = createApiClient(window.location.origin);
      try {
        const response = await apiClient.get('/auth/check-auth');

        if (response.status === 200 && response.data.isAuthenticated) {
          setIsAuthenticated(true);
          setUser(response.data.user);
          setAuthError(null);

          try {
            const tenantResponse = await apiClient.get('/api/users/check-tenant');
            if (tenantResponse.status === 200) {
              setTenantName(tenantResponse.data);
              setAuthError(null);
            } else {
              setAuthError('Utente non autorizzato per questa azienda.');
              setIsAuthenticated(false);
              setUser(null);
            }
          } catch (tenantError) {
            const axiosTenantError = tenantError as AxiosError;
            if (axiosTenantError.response?.status === 401) {
              setAuthError('Utente non autorizzato per questa azienda.');
              setIsAuthenticated(false);
              setUser(null);
            } else {
              console.error('Failed to check tenant authorization', axiosTenantError);
            }
          }
        } else {
          setIsAuthenticated(false);
          setUser(null);
          setAuthError('Utente non autenticato.');
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401 || axiosError.response?.status === 403) {
          setAuthError('Utente non autenticato.');
        } else {
          console.error('Failed to check authentication.', axiosError);
        }
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isUnprotectedPath) {
      checkAuth();
    }


    // Register callback to handle authentication errors
    setOnAuthErrorCallback(() => {
      setAuthError('Sessione scaduta. Effettuare nuovamente il login.');
      setIsAuthenticated(false);
      setUser(null);
    });
  }, []);

  const login = () => {
    const returnUrl = `${window.location.origin}/home`;
    const encodedReturnUrl = encodeURIComponent(returnUrl);
    const endpointAuth = `${hostAuth}/auth/login?returnUrl=${encodedReturnUrl}`;
    window.location.href = endpointAuth;
  };

  const logout = () => {
    const returnUrl = `${window.location.origin}/unauthorized`;
    const encodedReturnUrl = encodeURIComponent(returnUrl);
    const endpointAuth = `${hostAuth}/auth/logout?returnUrl=${encodedReturnUrl}`;
    window.location.href = endpointAuth;
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, user, tenantName, login, logout, authError, isMainDomain: isMainDomainState }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthState => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthContext };