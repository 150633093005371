import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../auth2/AuthProvider';

interface Product {
    id: number;
    name: string;
}

const UserProfile: React.FC = () => {
    const auth = useAuth();
    const [products, setProducts] = useState<Product[]>([]);

    // Set Axios to send cookies with every request
    axios.defaults.withCredentials = true;

    const fetchProducts = async () => {
        try {
            console.log('Fetching products...');
            const response = await axios.get<Product[]>('/products/all');
            console.log('Products fetched:', response.data);
            setProducts(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        if (auth.user) {
            console.log('User data:', auth.user);
        }
    }, [auth.user]);

    if (!auth.isLoggedIn || !auth.user) {
        return <div>Please log in</div>;
    }

    const claims = Array.isArray(auth.user.userClaims) ? auth.user.userClaims : [];

    return (
        <div>
            <div>Log in is successful!</div>
            <br/><br/>
            <p>Host: {auth.user.host}</p>
            <p>User: {auth.user.userName}</p>
            <ul>
                {claims.map((claim, index) => (
                    <li key={index}>
                        {claim.Type}: {claim.Value}
                    </li>
                ))}
            </ul>
            <h6>Products</h6>
            <ul>
                {products.map(product => (
                    <li key={product.id}>{product.name}</li>
                ))}
            </ul>
            <br/>
            <button onClick={fetchProducts}>Load Products</button>
        </div>
    );
};

export default UserProfile;
